
import { computed, defineComponent, PropType } from "vue";
import MailModel from "@/models/MailModel";
import AppIcon from "@/components/common/AppIcon.vue";
import CardListItem from "@/components/card/CardListItem.vue";
import { StatsEmailModel } from "@/models/StatsModel";
import { useStore } from "vuex";
import moment from "moment";
import { useRouter } from "vue-router";
import DropdownMenu from "@/components/common/dropdown/DropdownMenu.vue";
import DropdownMenuItem from "@/components/common/dropdown/DropdownMenuItem.vue";
import { openDialog } from "vue3-promise-dialog";
import AppConfirmation from "@/components/common/AppConfirmation.vue";
import { useI18n } from "vue-i18n";
import DropdownMenuDivider from "@/components/common/dropdown/DropdownMenuDivider.vue";
import axios from "axios";
import { useToast } from "primevue/usetoast";

const Action = {
    view: "view",
    edit: "edit",
    delete: "delete",
    archive: "archive",
    preview: "preview"
};
enum ActionEnum {
    delete = "delete",
    archive = "archive",
    preview = "preview"
}
enum StatusEnum {
    active = "active",
    draft = "draft",
    past = "past"
}
export default defineComponent({
    name: "MailsListItem",
    components: {
        DropdownMenuDivider,
        CardListItem,
        AppIcon,
        DropdownMenu,
        DropdownMenuItem
    },
    props: {
        mail: {
            type: Object as PropType<MailModel>,
            required: true
        },

        action: {
            type: String,
            default: Action.edit,
            validator: (value: string) => {
                return Object.values(Action).indexOf(value) !== -1;
            }
        },

        status: {
            type: String,
            default: StatusEnum.active,
            validator: (value: string) => {
                return (
                    Object.values(StatusEnum).indexOf(value as StatusEnum) !==
                    -1
                );
            }
        },

        contextMenuActions: {
            type: Array as PropType<string[]>,
            default: () => []
        }
    },
    emits: ["delete", "archive", "preview"],
    setup(props, { emit }) {
        const store = useStore();
        const router = useRouter();
        const { t } = useI18n();
        const toast = useToast();

        const mailStats = computed(
            (): StatsEmailModel =>
                store.getters["stats/getStatsByEmailId"](props.mail.id)
        );

        const sentOn = computed((): string => {
            return props.mail.publishUp
                ? moment(props.mail.publishUp).format("DD.MM.YYYY")
                : "";
        });

        const inFuture = computed((): boolean => {
            return props.mail.publishUp
                ? moment(props.mail.publishUp).valueOf() > moment().valueOf()
                : false;
        });

        const mailTitle = computed((): string => {
            return props.mail.name.length >= 38
                ? props.mail.name.substring(0, 35) + "..."
                : props.mail.name;
        });

        const listLabel = computed((): string => {
            return props.mail.lists
                ? props.mail.lists[0].name.length >= 36
                    ? props.mail.lists[0].name.substring(0, 33) + "..."
                    : props.mail.lists[0].name
                : "n/a";
        });

        const viewMail = () => {
            store.commit("mails/SET_ACTIVE_BY_ID", props.mail.id);
            router.push({
                name: "mail-view",
                params: { id: props.mail.id }
            });
        };
        const editMail = () => {
            store.commit("mails/SET_ACTIVE_BY_ID", props.mail.id);
            router.push({
                name: "mail-edit",
                params: { id: props.mail.id }
            });
        };
        const currency = computed(() => store.state.store.currency);

        const emitAction = (action: ActionEnum) => {
            emit(action);
        };

        const openMailPreview = (id: number) => {
            const url = computed(
                () => `${store.state.auth.baseUrl}/email/preview/${id}`
            );
            window.open(url.value, "_blank");
        };

        async function deleteMail(id: number) {
            if (
                (await openDialog(AppConfirmation, {
                    title: t("pages.mails.deleteModalHeader"),
                    message: t("pages.mails.deleteModalMessage"),
                    confirmButtonText: "general.delete",
                    type: "warning"
                })) === true
            ) {
                await store.dispatch("mails/deleteById", id);
                await store.dispatch("mails/fetchList").finally();
            }
        }

        const duplicateMail = (id: number) => {
            store.commit("mails/SET_ACTIVE_BY_ID", id);
            store.commit("mails/SET_DUPLICATE");
            router.push({ name: "mail-edit", params: { id: 0 } });
        };

        async function saveAsTemplate() {
            if (
                (await openDialog(AppConfirmation, {
                    title: t("pages.mail.saveAsTemplateHeader"),
                    message: t("pages.mail.saveAsTemplateMessage"),
                    confirmButtonText: "general.save"
                })) === true
            ) {
                const title = t("pages.mail.newTemplateTitle", {
                    title: props.mail.name
                });
                axios
                    .post("api/bee/template/new", {
                        template: props.mail.beeTemplate,
                        title: title
                    })
                    .then(() => {
                        toast.add({
                            severity: "success",
                            summary: "Template created",
                            detail: `Template '${title}' has been successfully created.`,
                            life: 3000
                        });
                    })
                    .catch(() => {
                        toast.add({
                            severity: "error",
                            summary: "Creating template failed",
                            detail: `There was an error saving row '${title}'. Please try again.`,
                            life: 3000
                        });
                    });
            }
        }

        return {
            mailStats,
            sentOn,
            inFuture,
            mailTitle,
            listLabel,
            viewMail,
            editMail,
            currency,
            emitAction,
            openMailPreview,
            deleteMail,
            duplicateMail,
            saveAsTemplate
        };
    }
});
