
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import store from "@/store";
import MailsListItem from "@/components/mails/MailsListItem.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import AppSection from "@/components/common/AppSection.vue";
import CardKpi from "@/components/card/CardKpi.vue";
import CardGroup from "@/components/card/CardGroup.vue";
import DropdownMenu from "@/components/common/dropdown/DropdownMenu.vue";
import DropdownMenuItem from "@/components/common/dropdown/DropdownMenuItem.vue";
import useKPIs from "@/composables/useKPIs";

export default defineComponent({
    name: "MailsListPage",
    components: {
        CardGroup,
        CardKpi,
        AppIcon,
        AppSection,
        DropdownMenu,
        DropdownMenuItem,
        MailsListItem
    },
    async beforeRouteEnter(to, from, next) {
        // If we already have emails, we reload in the background
        if (store.state.mails.total > 0) {
            store.dispatch("mails/fetchList").finally();
            next();
        } else {
            store.commit("app/SET_LOADING", true);
            await store.dispatch("mails/fetchList").then(() => {
                store.commit("app/SET_LOADING", false);
            });
            next();
        }
    },
    setup() {
        const store = useStore();
        const { kpiDays, kpis, kpisLoading } = useKPIs();

        const currency = computed(() => store.state.store.currency);

        return {
            kpis,
            kpiDays,
            kpisLoading,
            activeMails: computed(() => store.getters["mails/activeList"]),
            scheduledMails: computed(
                () => store.getters["mails/scheduledList"]
            ),
            draftMails: computed(() => store.getters["mails/draftsList"]),
            pastMails: computed(() => store.getters["mails/pastList"]),
            currency
        };
    }
});
