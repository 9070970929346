import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "leading-17 mb-3" }
const _hoisted_2 = { class: "grid grid-cols-1 md:grid-cols-3 gap-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_menu_item = _resolveComponent("dropdown-menu-item")!
  const _component_dropdown_menu = _resolveComponent("dropdown-menu")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_card_kpi = _resolveComponent("card-kpi")!
  const _component_card_group = _resolveComponent("card-group")!
  const _component_app_section = _resolveComponent("app-section")!
  const _component_mails_list_item = _resolveComponent("mails-list-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_section, { class: "pt-5 pb-8 bg-light-2" }, {
      default: _withCtx(() => [
        _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t("pages.mails.header")), 1),
        (_ctx.kpis)
          ? (_openBlock(), _createBlock(_component_card_group, {
              key: 0,
              title: _ctx.$t('general.performance')
            }, {
              sorting: _withCtx(() => [
                _createVNode(_component_dropdown_menu, {
                  label: 
                        `${_ctx.$t('general.last')} ${_ctx.kpiDays} ${_ctx.$t('general.days')}`
                    
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_dropdown_menu_item, {
                      label: `${_ctx.$t('general.last')} 7 ${_ctx.$t('general.days')}`,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.kpiDays = 7))
                    }, null, 8, ["label"]),
                    _createVNode(_component_dropdown_menu_item, {
                      label: 
                            `${_ctx.$t('general.last')} 30 ${_ctx.$t('general.days')}`
                        ,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.kpiDays = 30))
                    }, null, 8, ["label"]),
                    _createVNode(_component_dropdown_menu_item, {
                      label: 
                            `${_ctx.$t('general.last')} 60 ${_ctx.$t('general.days')}`
                        ,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.kpiDays = 60))
                    }, null, 8, ["label"]),
                    _createVNode(_component_dropdown_menu_item, {
                      label: 
                            `${_ctx.$t('general.last')} 90 ${_ctx.$t('general.days')}`
                        ,
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.kpiDays = 90))
                    }, null, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_card_kpi, {
                    header: _ctx.$t('pages.mails.totalMailsSent'),
                    value: _ctx.kpis.mail_sent_total.value,
                    trend: _ctx.kpis.mail_sent_total.trend,
                    loading: _ctx.kpisLoading
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_app_icon, { name: "MailOpen" })
                    ]),
                    _: 1
                  }, 8, ["header", "value", "trend", "loading"]),
                  _createVNode(_component_card_kpi, {
                    header: _ctx.$t('pages.mails.numberOfConversions'),
                    value: _ctx.kpis.mail_conversion_total.value,
                    trend: _ctx.kpis.mail_conversion_total.trend,
                    loading: _ctx.kpisLoading
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_app_icon, { name: "Conversion" })
                    ]),
                    _: 1
                  }, 8, ["header", "value", "trend", "loading"]),
                  _createVNode(_component_card_kpi, {
                    header: _ctx.$t('pages.mails.totalRevenue'),
                    currency: _ctx.currency,
                    value: 
                        _ctx.kpis.mail_revenue_total.value
                            ? parseFloat(
                                  _ctx.kpis.mail_revenue_total.value.toFixed(1)
                              )
                            : 0
                    ,
                    trend: _ctx.kpis.mail_revenue_total.trend,
                    loading: _ctx.kpisLoading
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_app_icon, { name: "CurrencyDollar" })
                    ]),
                    _: 1
                  }, 8, ["header", "currency", "value", "trend", "loading"])
                ])
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_app_section, { class: "pb-24" }, {
      default: _withCtx(() => [
        _createVNode(_component_card_group, {
          title: _ctx.$t('pages.mails.active'),
          class: "mt-10"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scheduledMails, (mail) => {
              return (_openBlock(), _createBlock(_component_mails_list_item, {
                mail: mail,
                key: mail.id,
                class: "mb-5",
                action: "edit",
                status: "active"
              }, null, 8, ["mail"]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeMails, (mail) => {
              return (_openBlock(), _createBlock(_component_mails_list_item, {
                mail: mail,
                key: mail.id,
                class: "mb-5",
                action: "view",
                status: "active"
              }, null, 8, ["mail"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["title"]),
        _createVNode(_component_card_group, {
          title: _ctx.$t('pages.mails.drafts'),
          class: "mt-10"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.draftMails, (mail) => {
              return (_openBlock(), _createBlock(_component_mails_list_item, {
                mail: mail,
                key: mail.id,
                class: "mb-5",
                action: "edit",
                status: "draft"
              }, null, 8, ["mail"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["title"]),
        _createVNode(_component_card_group, {
          title: _ctx.$t('pages.mails.past'),
          class: "mt-10"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pastMails, (mail) => {
              return (_openBlock(), _createBlock(_component_mails_list_item, {
                mail: mail,
                key: mail.id,
                class: "mb-5",
                action: "view",
                status: "past"
              }, null, 8, ["mail"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _: 1
    })
  ], 64))
}