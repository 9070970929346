import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, withModifiers as _withModifiers, createBlock as _createBlock, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center" }
const _hoisted_2 = { class: "flex justify-start items-end h-9" }
const _hoisted_3 = {
  key: 0,
  class: "block inline-flex"
}
const _hoisted_4 = {
  key: 1,
  class: "inline-flex"
}
const _hoisted_5 = { class: "flex items-center justify-end" }
const _hoisted_6 = { class: "grid grid-cols-2 gap-4 mb-4 px-4" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "inline-flex items-center justify-start leading-6 py-2"
}
const _hoisted_9 = { class: "inline-block mr-2 text-sm leading-6" }
const _hoisted_10 = { class: "font-bold" }
const _hoisted_11 = { class: "font-bold mr-1" }
const _hoisted_12 = {
  key: 1,
  class: "flex-col justify-start items center"
}
const _hoisted_13 = { class: "text-sm" }
const _hoisted_14 = { class: "mt-4" }
const _hoisted_15 = { class: "flex justify-start mt-4" }
const _hoisted_16 = { class: "mt-8" }
const _hoisted_17 = {
  key: 0,
  class: "leading-6 py-2"
}
const _hoisted_18 = {
  key: 1,
  class: "flex-col justify-start items center"
}
const _hoisted_19 = { class: "text-sm" }
const _hoisted_20 = { class: "flex justify-start mt-4" }
const _hoisted_21 = { class: "text-sm text-dark-3 mt-3 w-100" }
const _hoisted_22 = { class: "flex justify-start mt-4" }
const _hoisted_23 = { class: "mt-8" }
const _hoisted_24 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_25 = { class: "text-sm font-bold leading-4" }
const _hoisted_26 = { class: "text-sm font-normal leading-4 mt-2" }
const _hoisted_27 = {
  key: 1,
  class: "flex-col justify-start items center"
}
const _hoisted_28 = { class: "text-sm" }
const _hoisted_29 = { class: "flex flex-col justify-start mt-4" }
const _hoisted_30 = { class: "mt-8" }
const _hoisted_31 = { class: "hidden md:flex flex-1" }
const _hoisted_32 = { class: "flex flex-col" }
const _hoisted_33 = {
  key: 0,
  class: "py-2"
}
const _hoisted_34 = {
  key: 0,
  class: "inline-block mr-2 text-sm leading-6"
}
const _hoisted_35 = { class: "font-bold" }
const _hoisted_36 = {
  key: 1,
  class: "inline-block mr-2 text-sm leading-6"
}
const _hoisted_37 = { class: "font-bold" }
const _hoisted_38 = {
  key: 2,
  class: "inline-block mr-2 text-sm leading-6"
}
const _hoisted_39 = { class: "font-bold" }
const _hoisted_40 = {
  key: 3,
  class: "inline-block mr-2 text-sm leading-6"
}
const _hoisted_41 = { class: "font-bold" }
const _hoisted_42 = {
  key: 1,
  class: "flex-col justify-start items center"
}
const _hoisted_43 = { class: "text-sm" }
const _hoisted_44 = { class: "flex justify-start mt-4 flex-wrap" }
const _hoisted_45 = { class: "flex justify-start mt-4" }
const _hoisted_46 = { class: "mt-8" }
const _hoisted_47 = { class: "flex flex-col justify-start items-center" }
const _hoisted_48 = { class: "inline-flex p-2 rounded-full bg-red-100 text-red-600" }
const _hoisted_49 = { class: "text-lg font-medium mt-4" }
const _hoisted_50 = { class: "text-sm mt-4" }
const _hoisted_51 = { class: "grid grid-cols-2 gap-3 w-full mt-6" }
const _hoisted_52 = { class: "flex flex-col justify-start items-center" }
const _hoisted_53 = { class: "inline-flex p-2 rounded-full bg-red-100 text-red-600" }
const _hoisted_54 = { class: "text-lg font-medium mt-4" }
const _hoisted_55 = { class: "text-sm mt-4" }
const _hoisted_56 = { class: "grid grid-cols-2 gap-3 w-full mt-6" }
const _hoisted_57 = { class: "flex flex-col justify-start items-center" }
const _hoisted_58 = { class: "inline-flex p-2 rounded-full bg-red-100 text-red-600" }
const _hoisted_59 = { class: "text-lg font-medium mt-4" }
const _hoisted_60 = ["innerHTML"]
const _hoisted_61 = { class: "grid grid-cols-2 gap-3 w-full mt-6" }
const _hoisted_62 = { class: "flex flex-col justify-start items-center" }
const _hoisted_63 = { class: "inline-flex p-2 rounded-full bg-green-50 text-green-400" }
const _hoisted_64 = ["innerHTML"]
const _hoisted_65 = { class: "flex justify-center items-center mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_section = _resolveComponent("app-section")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_calendar = _resolveComponent("calendar")!
  const _component_dropdown_base = _resolveComponent("dropdown-base")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_alert = _resolveComponent("app-alert")!
  const _component_card_list_item = _resolveComponent("card-list-item")!
  const _component_mail_preview_content = _resolveComponent("mail-preview-content")!
  const _component_bee_editor = _resolveComponent("bee-editor")!
  const _component_card_group = _resolveComponent("card-group")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_section, { class: "py-3 bg-light-3" }, {
      default: _withCtx(() => [
        _createVNode(_component_app_button, {
          "button-style": "transparent",
          onClick: _ctx.goToMails
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("pages.mail.overview")), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_app_button, { "button-style": "white" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("pages.mail.viewMail")), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_app_section, { class: "py-8 bg-light-2" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.activeItem !== 'name')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("h1", {
                    class: "hover:underline cursor-pointer",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeItem = 'name'))
                  }, _toDisplayString(_ctx.shortName), 1),
                  _createVNode(_component_app_button, {
                    "button-style": "transparentUnderline",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeItem = 'name'))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("pages.mail.editName")), 1)
                    ]),
                    _: 1
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_app_input, {
                    modelValue: _ctx.name,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.name) = $event)),
                    class: "w-80",
                    onKeyup: [
                      _withKeys(_ctx.save, ["enter"]),
                      _withKeys(_ctx.reset, ["esc"])
                    ]
                  }, null, 8, ["modelValue", "onKeyup"]),
                  _createVNode(_component_app_icon, {
                    name: "CheckCircle",
                    class: "ml-5 cursor-pointer",
                    onClick: _ctx.save
                  }, null, 8, ["onClick"]),
                  _createVNode(_component_app_icon, {
                    name: "XCircle",
                    class: "ml-2 cursor-pointer",
                    onClick: _withModifiers(_ctx.reset, ["stop"])
                  }, null, 8, ["onClick"])
                ]))
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.isSaving)
              ? (_openBlock(), _createBlock(_component_app_icon, {
                  key: 0,
                  name: "Refresh",
                  class: "mr-1 animate-spin",
                  size: "5"
                }))
              : _createCommentVNode("", true),
            (_ctx.mail && !_ctx.mail.id)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 1,
                  "button-style": "transparentUnderline",
                  onClick: _ctx.save
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("pages.mail.saveDraft")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_dropdown_base, {
              open: _ctx.isDatepickerOpen,
              "onUpdate:open": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isDatepickerOpen) = $event))
            }, {
              head: _withCtx(() => [
                _createVNode(_component_app_button, {
                  "button-style": "white",
                  disabled: !_ctx.canSchedule,
                  "disabled-style": "lighter",
                  class: "ml-5",
                  onClick: _ctx.scheduleButtonHandler
                }, {
                  left: _withCtx(() => [
                    _createVNode(_component_app_icon, {
                      name: "Calendar",
                      class: "mr-1",
                      size: "5"
                    })
                  ]),
                  default: _withCtx(() => [
                    (_ctx.publishUpFormatted.length)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(_toDisplayString(_ctx.publishUpFormatted), 1)
                        ], 64))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(_ctx.$t("pages.mail.schedule")), 1)
                        ], 64))
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ]),
              body: _withCtx(() => [
                _createVNode(_component_calendar, {
                  modelValue: _ctx.dateTime,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dateTime) = $event)),
                  dateFormat: "yyyy-MM-DD",
                  inline: true,
                  showTime: true,
                  minDate: _ctx.today
                }, null, 8, ["modelValue", "minDate"]),
                _createElementVNode("div", _hoisted_6, [
                  (_ctx.publishUpFormatted)
                    ? (_openBlock(), _createBlock(_component_app_button, {
                        key: 0,
                        "button-style": "secondary",
                        onClick: _ctx.clearSchedule
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("pages.mail.clearSchedule")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7)),
                  _createVNode(_component_app_button, {
                    "button-style": "primary",
                    onClick: _ctx.scheduleButtonHandler
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("general.apply")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]),
              _: 1
            }, 8, ["open"]),
            _createVNode(_component_app_button, {
              class: "ml-5",
              disabled: !_ctx.canSave,
              "disabled-style": "lighter",
              onClick: _ctx.openSendConfirmationModal
            }, {
              left: _withCtx(() => [
                _createVNode(_component_app_icon, {
                  name: "Mail",
                  class: "mr-1",
                  size: "5"
                })
              ]),
              default: _withCtx(() => [
                _createTextVNode(" " + _toDisplayString(_ctx.$t("pages.mail.send")), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_app_section, { class: "py-10" }, {
      default: _withCtx(() => [
        _createVNode(_component_card_group, {
          title: _ctx.$t('pages.mail.configuration')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_card_list_item, {
              title: _ctx.$t('pages.mail.receiver'),
              status: _ctx.activeItem === 'receiver' ? 'muted' : 'success',
              active: _ctx.activeItem === 'receiver',
              class: "mb-5 text-sm",
              disabled: !!_ctx.activeItem && _ctx.activeItem !== 'receiver',
              "show-actions": _ctx.activeItem !== 'receiver',
              onEdit: _cache[7] || (_cache[7] = ($event: any) => (_ctx.activeItem = 'receiver'))
            }, {
              default: _withCtx(() => [
                (_ctx.activeItem !== 'receiver')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_app_icon, {
                        name: "Users",
                        size: "5",
                        class: "mr-1"
                      }),
                      _createElementVNode("span", _hoisted_9, [
                        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("pages.mail.audience")) + ":", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.audienceLabel), 1)
                      ]),
                      _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("pages.mail.segment")) + ":", 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.listLabel) + " (" + _toDisplayString(_ctx.listLabelCount) + ") ", 1)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("pages.mail.segmentHeader")), 1),
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _createVNode(_component_app_select, {
                            modelValue: _ctx.audience,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.audience) = $event)),
                            options: _ctx.audienceOptions,
                            label: _ctx.$t('pages.mail.audience'),
                            class: "w-96 mr-6"
                          }, null, 8, ["modelValue", "options", "label"]),
                          _createVNode(_component_app_select, {
                            modelValue: _ctx.list,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.list) = $event)),
                            options: _ctx.segmentOptions,
                            label: _ctx.$t('pages.mail.segment'),
                            class: "w-96"
                          }, null, 8, ["modelValue", "options", "label"])
                        ]),
                        _createVNode(_component_app_alert, {
                          type: "info",
                          class: "mt-8"
                        }, {
                          header: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("pages.mail.segmentAlertHeader")), 1)
                          ]),
                          content: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("pages.mail.segmentAlertContent", {
                                        email: "john@example.com"
                                    })), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        _createVNode(_component_app_button, { onClick: _ctx.save }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("general.save")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_app_button, {
                          "button-style": "transparentUnderline",
                          onClick: _ctx.reset
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("general.cancel")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ])
                    ]))
              ]),
              _: 1
            }, 8, ["title", "status", "active", "disabled", "show-actions"]),
            _createVNode(_component_card_list_item, {
              title: _ctx.$t('pages.mail.sender'),
              status: _ctx.activeItem === 'sender' ? 'muted' : 'success',
              active: _ctx.activeItem === 'sender',
              class: "mb-5 text-sm",
              disabled: !!_ctx.activeItem && _ctx.activeItem !== 'sender',
              "show-actions": _ctx.activeItem !== 'sender',
              onEdit: _cache[11] || (_cache[11] = ($event: any) => (_ctx.activeItem = 'sender'))
            }, {
              default: _withCtx(() => [
                (_ctx.activeItem !== 'sender')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createTextVNode(_toDisplayString(_ctx.fromName) + " ", 1),
                      (_ctx.fromAddress)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode("-")
                          ], 64))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(_ctx.fromAddress), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t("pages.mail.senderHeader")), 1),
                      _createElementVNode("div", _hoisted_20, [
                        _createVNode(_component_app_input, {
                          modelValue: _ctx.fromName,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.fromName) = $event)),
                          label: _ctx.$t('pages.mail.name'),
                          class: "w-100 mr-6"
                        }, null, 8, ["modelValue", "label"]),
                        _createVNode(_component_app_select, {
                          modelValue: _ctx.fromAddress,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.fromAddress) = $event)),
                          label: _ctx.$t('pages.mail.email'),
                          options: _ctx.emailOptions,
                          class: "w-80"
                        }, null, 8, ["modelValue", "label", "options"])
                      ]),
                      _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("pages.mail.senderText")), 1),
                      _createElementVNode("div", _hoisted_22, [
                        _createVNode(_component_app_input, {
                          modelValue: _ctx.replyToAddress,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.replyToAddress) = $event)),
                          label: _ctx.$t('pages.mail.replyTo'),
                          status: _ctx.replyToAddressStatus,
                          description: _ctx.replyToAddressDescription,
                          class: "w-100 mr-6"
                        }, null, 8, ["modelValue", "label", "status", "description"])
                      ]),
                      _createElementVNode("div", _hoisted_23, [
                        _createVNode(_component_app_button, { onClick: _ctx.save }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("general.save")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_app_button, {
                          "button-style": "transparentUnderline",
                          onClick: _ctx.reset
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("general.cancel")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ])
                    ]))
              ]),
              _: 1
            }, 8, ["title", "status", "active", "disabled", "show-actions"]),
            _createVNode(_component_card_list_item, {
              title: _ctx.$t('pages.mail.subject'),
              status: _ctx.subjectStatus,
              active: _ctx.activeItem === 'subject',
              class: "mb-5 text-sm",
              disabled: !!_ctx.activeItem && _ctx.activeItem !== 'subject',
              "show-actions": _ctx.activeItem !== 'subject',
              onEdit: _cache[14] || (_cache[14] = ($event: any) => (_ctx.activeItem = 'subject'))
            }, {
              default: _withCtx(() => [
                (_ctx.activeItem !== 'subject')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                      _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.subject || "–"), 1),
                      _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t("pages.mail.preheader")) + " " + _toDisplayString(_ctx.preheaderText || "–"), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_27, [
                      _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t("pages.mail.subjectHeader")), 1),
                      _createElementVNode("div", _hoisted_29, [
                        _createVNode(_component_app_input, {
                          modelValue: _ctx.subject,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.subject) = $event)),
                          label: `${_ctx.$t('pages.mail.subject')} *`,
                          status: _ctx.subjectInputStatus,
                          description: _ctx.subjectInputDescription,
                          counter: 100,
                          class: "w-100 mb-4"
                        }, null, 8, ["modelValue", "label", "status", "description"]),
                        _createVNode(_component_app_input, {
                          modelValue: _ctx.preheaderText,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.preheaderText) = $event)),
                          label: _ctx.$t('pages.mail.preheaderText'),
                          status: _ctx.preheaderTextInputStatus,
                          description: _ctx.preheaderTextInputDescription,
                          counter: 100,
                          limit: 150,
                          class: "w-100"
                        }, null, 8, ["modelValue", "label", "status", "description"])
                      ]),
                      _createElementVNode("div", _hoisted_30, [
                        _createVNode(_component_app_button, { onClick: _ctx.save }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("general.save")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_app_button, {
                          "button-style": "transparentUnderline",
                          onClick: _ctx.reset
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("general.cancel")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ])
                    ]))
              ]),
              _: 1
            }, 8, ["title", "status", "active", "disabled", "show-actions"]),
            _createVNode(_component_card_list_item, {
              title: _ctx.$t('pages.mail.content'),
              status: _ctx.html.length ? 'success' : 'danger',
              class: "mb-5 text-sm",
              disabled: !!_ctx.activeItem && _ctx.activeItem !== 'editor',
              onEdit: _ctx.editTemplate
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_31, [
                  _createVNode(_component_mail_preview_content, { html: _ctx.html }, null, 8, ["html"]),
                  _withDirectives(_createElementVNode("div", _hoisted_32, [
                    _createVNode(_component_app_button, {
                      class: "pt-1",
                      onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.sendTestMail(_ctx.mail.id))),
                      "button-style": "transparentUnderline"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("components.mails.editContent.sendTestMail")), 1)
                      ]),
                      _: 1
                    })
                  ], 512), [
                    [_vShow, _ctx.html.length]
                  ])
                ]),
                _createVNode(_component_bee_editor, {
                  "template-content": _ctx.templateContent,
                  "template-name": _ctx.templateName,
                  "template-key": _ctx.templateKey,
                  "template-type": _ctx.templateType,
                  "send-test-mail-enabled": true,
                  "save-as-template-enabled": true,
                  "switch-template-enabled": true,
                  "email-id": _ctx.mail.id,
                  onSave: _ctx.saveTemplateContent,
                  onExit: _ctx.exitEditor
                }, null, 8, ["template-content", "template-name", "template-key", "template-type", "email-id", "onSave", "onExit"])
              ]),
              _: 1
            }, 8, ["title", "status", "disabled", "onEdit"]),
            _createVNode(_component_card_list_item, {
              title: _ctx.$t('pages.mail.utmTitle'),
              status: _ctx.activeItem === 'umt' ? 'muted' : 'success',
              active: _ctx.activeItem === 'utm',
              class: "mb-5 text-sm",
              disabled: !!_ctx.activeItem && _ctx.activeItem !== 'utm',
              "show-actions": _ctx.activeItem !== 'utm',
              onEdit: _cache[20] || (_cache[20] = ($event: any) => (_ctx.activeItem = 'utm'))
            }, {
              default: _withCtx(() => [
                (_ctx.activeItem !== 'utm')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                      (_ctx.utmSource)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_34, [
                            _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.$t("pages.mail.source")) + ":", 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.utmSource), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.utmMedium)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_36, [
                            _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.$t("pages.mail.medium")) + ":", 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.utmMedium), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.utmCampaign)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_38, [
                            _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.$t("pages.mail.name")) + ":", 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.utmCampaign), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.utmContent)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_40, [
                            _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.$t("pages.mail.content")) + ":", 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.utmContent), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_42, [
                      _createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.$t("pages.mail.utmHeader")), 1),
                      _createElementVNode("div", _hoisted_44, [
                        _createVNode(_component_app_input, {
                          modelValue: _ctx.utmSource,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.utmSource) = $event)),
                          label: `${_ctx.$t('pages.mail.source')} *`,
                          class: "w-100 mr-6"
                        }, null, 8, ["modelValue", "label"]),
                        _createVNode(_component_app_input, {
                          modelValue: _ctx.utmMedium,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.utmMedium) = $event)),
                          label: _ctx.$t('pages.mail.medium'),
                          class: "w-100"
                        }, null, 8, ["modelValue", "label"])
                      ]),
                      _createElementVNode("div", _hoisted_45, [
                        _createVNode(_component_app_input, {
                          modelValue: _ctx.utmCampaign,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.utmCampaign) = $event)),
                          label: _ctx.$t('pages.mail.name'),
                          class: "w-100 mr-6"
                        }, null, 8, ["modelValue", "label"]),
                        _createVNode(_component_app_input, {
                          modelValue: _ctx.utmContent,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.utmContent) = $event)),
                          label: _ctx.$t('pages.mail.content'),
                          class: "w-100"
                        }, null, 8, ["modelValue", "label"])
                      ]),
                      _createElementVNode("div", _hoisted_46, [
                        _createVNode(_component_app_button, { onClick: _ctx.save }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("general.save")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_app_button, {
                          "button-style": "transparentUnderline",
                          onClick: _ctx.reset
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("general.cancel")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ])
                    ]))
              ]),
              _: 1
            }, 8, ["title", "status", "active", "disabled", "show-actions"])
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _: 1
    }),
    (_ctx.unsavedModalOpen)
      ? (_openBlock(), _createBlock(_component_app_modal, {
          key: 0,
          onClose: _cache[23] || (_cache[23] = ($event: any) => (_ctx.unsavedModalOpen = false))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_47, [
              _createElementVNode("div", _hoisted_48, [
                _createVNode(_component_app_icon, {
                  name: "Exclamation",
                  size: "8"
                })
              ]),
              _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.$t("pages.mail.unsavedModalHeader")), 1),
              _createElementVNode("div", _hoisted_50, _toDisplayString(_ctx.$t("pages.mail.unsavedModalText")), 1),
              _createElementVNode("div", _hoisted_51, [
                _createVNode(_component_app_button, {
                  onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.exit(false))),
                  "button-style": "secondary",
                  class: "w-full"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("pages.mail.exitWithoutSaving")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_app_button, {
                  onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.exit(true))),
                  class: "w-full"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("pages.mail.saveAndExit")), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.scheduleConfirmationModalOpen)
      ? (_openBlock(), _createBlock(_component_app_modal, {
          key: 1,
          onClose: _cache[25] || (_cache[25] = ($event: any) => (_ctx.scheduleConfirmationModalOpen = false))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_52, [
              _createElementVNode("div", _hoisted_53, [
                _createVNode(_component_app_icon, {
                  name: "Exclamation",
                  size: "8"
                })
              ]),
              _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.$t("pages.mail.scheduleModalHeader")), 1),
              _createElementVNode("div", _hoisted_55, _toDisplayString(_ctx.$t("pages.mail.scheduleModalText", {
                        date: _ctx.dateTimeFormatted
                    })), 1),
              _createElementVNode("div", _hoisted_56, [
                _createVNode(_component_app_button, {
                  "button-style": "secondary",
                  onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.scheduleConfirmationModalOpen = false)),
                  class: "w-full"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("general.cancel")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_app_button, {
                  onClick: _ctx.schedule,
                  class: "w-full"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("pages.mail.scheduleMail")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.sendConfirmationModalOpen)
      ? (_openBlock(), _createBlock(_component_app_modal, {
          key: 2,
          onClose: _cache[27] || (_cache[27] = ($event: any) => (_ctx.sendConfirmationModalOpen = false))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_57, [
              _createElementVNode("div", _hoisted_58, [
                _createVNode(_component_app_icon, {
                  name: "Exclamation",
                  size: "8"
                })
              ]),
              _createElementVNode("div", _hoisted_59, _toDisplayString(_ctx.$t("pages.mail.sendModalHeader")), 1),
              _createElementVNode("div", {
                class: "text-sm mt-4",
                innerHTML: 
                    _ctx.$t('pages.mail.sendModalText', {
                        number: _ctx.listLabelCount,
                        name: _ctx.listLabel
                    })
                
              }, null, 8, _hoisted_60),
              _createElementVNode("div", _hoisted_61, [
                _createVNode(_component_app_button, {
                  "button-style": "secondary",
                  onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.sendConfirmationModalOpen = false)),
                  class: "w-full"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("general.cancel")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_app_button, {
                  onClick: _ctx.send,
                  class: "w-full"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("pages.mail.sendMail")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.congratulationsModalOpen)
      ? (_openBlock(), _createBlock(_component_app_modal, {
          key: 3,
          onClose: _cache[28] || (_cache[28] = ($event: any) => (_ctx.congratulationsModalOpen = false))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_62, [
              _createElementVNode("div", _hoisted_63, [
                _createVNode(_component_app_icon, {
                  name: "Mail",
                  size: "8"
                })
              ]),
              _createElementVNode("div", {
                class: "text-sm mt-4",
                innerHTML: 
                    _ctx.$t('pages.mail.congratulations', {
                        number: _ctx.listLabelCount
                    })
                
              }, null, 8, _hoisted_64),
              _createElementVNode("div", _hoisted_65, [
                _createVNode(_component_app_button, {
                  onClick: _ctx.closeCongratulationsModal,
                  class: "w-full"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("general.done")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}