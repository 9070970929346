
import { computed, defineComponent, onMounted } from "vue";
import store from "@/store";
import { useStore } from "vuex";
import AppIcon from "@/components/common/AppIcon.vue";
import AppButton from "@/components/common/AppButton.vue";
import CardKpi from "@/components/card/CardKpi.vue";
import AppSection from "@/components/common/AppSection.vue";
import CardGroup from "@/components/card/CardGroup.vue";
import CardListItem from "@/components/card/CardListItem.vue";
import MailModel from "@/models/MailModel";
import { StatsEmailModel } from "@/models/StatsModel";
import { useRouter } from "vue-router";
import MailPreviewContent from "@/components/mails/MailPreviewContent.vue";
import SegmentModel from "@/models/SegmentModel";

export default defineComponent({
    name: "MailViewPage",
    components: {
        CardKpi,
        AppSection,
        CardGroup,
        AppIcon,
        AppButton,
        CardListItem,
        MailPreviewContent
    },
    async beforeRouteEnter(to, from, next) {
        if (store.state.mails.active) {
            next();
            return;
        }

        if (to.params.id === "0") {
            next({ name: "mails-list" });
            return;
        }

        const mailInList = store.getters["mails/getById"](
            parseInt(to.params.id as string)
        );

        if (mailInList !== undefined) {
            store.commit("mails/SET_ACTIVE", mailInList);
        } else {
            store.commit("app/SET_LOADING", true);
            await store.dispatch("mails/fetchActive", to.params.id);
            store.commit("app/SET_LOADING", false);
        }
        next();
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const { mails, segments } = store.state;
        const mail = computed((): MailModel => store.state.mails.active);
        const mailStats = computed(
            (): StatsEmailModel =>
                store.getters["stats/getStatsByEmailId"](
                    store.state.mails.active.id
                )
        );

        const mailSegment = computed(() => {
                return segments.list.find(
                    (segment: SegmentModel) =>
                        segment.id ===
                        (mails.active.lists.length
                            ? mails.active.lists[0].id
                            : 0)
                );
            }),
            listLabel = computed((): string => {
                return mailSegment.value
                    ? mailSegment.value.name
                    : mails.active.lists.length
                    ? mails.active.lists[0].name
                    : "-";
            }),
            listLabelCount = computed((): number => {
                return mailSegment.value ? mailSegment.value.leadCount : "-";
            });
        const goToMails = () => {
            router.push({ name: "mails-list" });
        };
        const duplicateMail = () => {
            store.commit("mails/SET_DUPLICATE");
            router.push({ name: "mail-edit", params: { id: 0 } });
        };
        const currency = computed(() => store.state.store.currency);
        onMounted(() => {
            window.analytics.track("Mail Viewed", {
                userId: store.getters["user/emailHash"],
                mailId: store.state.mails.active.id,
                mailStatus: store.getters["mails/activeStatus"]
            });
        });
        return {
            mail,
            mailStats,
            listLabel,
            listLabelCount,
            goToMails,
            duplicateMail,
            currency
        };
    }
});
