import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center" }
const _hoisted_2 = { class: "leading-17 mb-3" }
const _hoisted_3 = { class: "grid grid-cols-1 md:grid-cols-3 gap-7" }
const _hoisted_4 = { class: "inline-flex items-center justify-start leading-6 py-2" }
const _hoisted_5 = { class: "font-bold mr-1" }
const _hoisted_6 = { class: "leading-6 py-2" }
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = { class: "text-sm font-bold leading-4" }
const _hoisted_9 = { class: "text-sm font-normal leading-4 mt-2" }
const _hoisted_10 = {
  key: 0,
  class: "py-2"
}
const _hoisted_11 = {
  key: 0,
  class: "inline-block mr-2 text-sm leading-6"
}
const _hoisted_12 = { class: "font-bold" }
const _hoisted_13 = {
  key: 1,
  class: "inline-block mr-2 text-sm leading-6"
}
const _hoisted_14 = { class: "font-bold" }
const _hoisted_15 = {
  key: 2,
  class: "inline-block mr-2 text-sm leading-6"
}
const _hoisted_16 = { class: "font-bold" }
const _hoisted_17 = {
  key: 3,
  class: "inline-block mr-2 text-sm leading-6"
}
const _hoisted_18 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_section = _resolveComponent("app-section")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_card_kpi = _resolveComponent("card-kpi")!
  const _component_card_group = _resolveComponent("card-group")!
  const _component_card_list_item = _resolveComponent("card-list-item")!
  const _component_mail_preview_content = _resolveComponent("mail-preview-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_section, { class: "py-3 bg-light-3" }, {
      default: _withCtx(() => [
        _createVNode(_component_app_button, {
          "button-style": "breadcrumbStart",
          onClick: _ctx.goToMails
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("pages.mail.overview")), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_app_button, { "button-style": "breadcrumb" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("pages.mail.viewMail")), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_app_section, { class: "pt-5 pb-8 bg-light-2" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.mail.name), 1),
          _createVNode(_component_app_button, { onClick: _ctx.duplicateMail }, {
            left: _withCtx(() => [
              _createVNode(_component_app_icon, {
                name: "Duplicate",
                "fill-type": "stroke",
                class: "mr-1",
                size: "5"
              })
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_ctx.$t("pages.mail.duplicate")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        (_ctx.mailStats !== undefined)
          ? (_openBlock(), _createBlock(_component_card_group, {
              key: 0,
              title: _ctx.$t('general.performance')
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_card_kpi, {
                    header: 
                        `${_ctx.mailStats.total_sent} ${_ctx.$t(
                            'pages.mail.totalMailsSent'
                        )}:`
                    ,
                    text: `${_ctx.mailStats.open_rate.toFixed(1)}%`
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_app_icon, { name: "MailOpen" })
                    ]),
                    _: 1
                  }, 8, ["header", "text"]),
                  _createVNode(_component_card_kpi, {
                    header: _ctx.$t('pages.mails.numberOfConversions'),
                    value: _ctx.mailStats.total_conversions
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_app_icon, { name: "Conversion" })
                    ]),
                    _: 1
                  }, 8, ["header", "value"]),
                  _createVNode(_component_card_kpi, {
                    header: _ctx.$t('pages.mails.totalRevenue'),
                    currency: _ctx.currency,
                    value: parseFloat(_ctx.mailStats.total_revenue.toFixed(1))
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_app_icon, { name: "CurrencyDollar" })
                    ]),
                    _: 1
                  }, 8, ["header", "currency", "value"])
                ])
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_app_section, { class: "py-10" }, {
      default: _withCtx(() => [
        _createVNode(_component_card_group, {
          title: _ctx.$t('pages.mail.configuration')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_card_list_item, {
              title: _ctx.$t('pages.mail.receiver'),
              status: "success",
              class: "mb-5 text-sm",
              "action-disabled": true
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_app_icon, {
                    name: "Users",
                    size: "5",
                    class: "mr-1"
                  }),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("pages.mail.segment")) + ":", 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.listLabel) + " (" + _toDisplayString(_ctx.listLabelCount) + ") ", 1)
                ])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_card_list_item, {
              title: _ctx.$t('pages.mail.sender'),
              status: "success",
              class: "mb-5 text-sm",
              "action-disabled": true
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createTextVNode(_toDisplayString(_ctx.mail.fromName) + " ", 1),
                  (_ctx.mail.fromAddress)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode("-")
                      ], 64))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(_ctx.mail.fromAddress), 1)
                ])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_card_list_item, {
              title: _ctx.$t('pages.mail.subject'),
              status: "success",
              class: "mb-5 text-sm",
              "action-disabled": true
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.mail.subject || "–"), 1),
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("pages.mail.preheader")) + " " + _toDisplayString(_ctx.mail.preheaderText || "–"), 1)
                ])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_card_list_item, {
              title: _ctx.$t('pages.mail.content'),
              status: _ctx.mail.customHtml.length ? 'success' : 'danger',
              class: "mb-5 text-sm",
              "action-disabled": true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_mail_preview_content, {
                  html: _ctx.mail.customHtml
                }, null, 8, ["html"])
              ]),
              _: 1
            }, 8, ["title", "status"]),
            _createVNode(_component_card_list_item, {
              title: _ctx.$t('pages.mail.utmTitle'),
              status: "success",
              class: "mb-5 text-sm",
              "action-disabled": true
            }, {
              default: _withCtx(() => [
                (!!_ctx.mail.utmTags)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      (!!_ctx.mail.utmTags.utmSource)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("pages.mail.source")) + ":", 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.mail.utmTags.utmSource), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (!!_ctx.mail.utmTags.utmMedium)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t("pages.mail.medium")) + ":", 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.mail.utmTags.utmMedium), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (!!_ctx.mail.utmTags.utmCampaign)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                            _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("pages.mail.name")) + ":", 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.mail.utmTags.utmCampaign), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (!!_ctx.mail.utmTags.utmContent)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_17, [
                            _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t("pages.mail.content")) + ":", 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.mail.utmTags.utmContent), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _: 1
    })
  ], 64))
}