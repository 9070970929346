
import { computed, defineComponent, ref, watch } from "vue";
import store from "@/store";
import { useStore } from "vuex";
import AppIcon from "@/components/common/AppIcon.vue";
import AppInput from "@/components/common/AppInput.vue";
import AppAlert from "@/components/common/AppAlert.vue";
import AppModal from "@/components/common/AppModal.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppSelect from "@/components/common/AppSelect.vue";
import AppSection from "@/components/common/AppSection.vue";
import CardGroup from "@/components/card/CardGroup.vue";
import CardListItem from "@/components/card/CardListItem.vue";
import MailModel from "@/models/MailModel";
import Calendar from "primevue/calendar";
import { useToast } from "primevue/usetoast";
import {
    onBeforeRouteLeave,
    onBeforeRouteUpdate,
    useRoute,
    useRouter
} from "vue-router";
import SegmentModel from "@/models/SegmentModel";
import { SelectOptionInterface } from "@/types";
import EmailAddressModel from "@/models/EmailAddressModel";
import DropdownBase from "@/components/common/dropdown/DropdownBase.vue";
import moment from "moment";
import { useI18n } from "vue-i18n";
import AudienceModel from "@/models/AudienceModel";
import MailPreviewContent from "@/components/mails/MailPreviewContent.vue";
import BeeEditor from "@/components/editor/BeeEditor.vue";
import useSendTestMail from "@/composables/useSendTestMail";
import useUnicodeHelper from "@/composables/useUnicodeHelper";
import TemplateModel from "@/models/TemplateModel";
import { openDialog } from "vue3-promise-dialog";
import ChooseTemplateModal from "@/components/editor/ChooseTemplateModal.vue";

export default defineComponent({
    name: "MailViewPage",
    components: {
        MailPreviewContent,
        CardGroup,
        AppIcon,
        AppInput,
        AppAlert,
        AppModal,
        AppButton,
        AppSelect,
        AppSection,
        CardListItem,
        Calendar,
        DropdownBase,
        BeeEditor
    },
    async beforeRouteEnter(to, from, next) {
        if (!store.state.audiences) {
            store.dispatch("audiences/fetchAudiences").finally();
        }

        if (!store.state.buildertokens.is_fetched) {
            store.dispatch("buildertokens/fetchState").finally();
        }

        if (store.state.mails.active) {
            next();
            return;
        } else {
            if (to.params.id === "0") {
                // Directly entered new mail route
                store.commit("mails/SET_ACTIVE_NEW");
                next();
                return;
            } else {
                // Directly entered edit existing mail route
                const mailInList = store.getters["mails/getById"](
                    parseInt(to.params.id as string)
                );

                if (mailInList !== undefined) {
                    store.commit("mails/SET_ACTIVE", mailInList);
                } else {
                    store.commit("app/SET_LOADING", true);
                    await store.dispatch("mails/fetchActive", to.params.id);
                    store.commit("app/SET_LOADING", false);
                }

                next();
            }
        }
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();
        const { t } = useI18n();
        const { sendTestMail } = useSendTestMail();
        const { encodeUnicode, decodeUnicode } = useUnicodeHelper();
        const { mails, segments, audiences } = store.state;
        const mail = computed((): MailModel => mails.active);

        const unsavedChanges = ref(mails.active && !mails.active.id);
        const unsavedModalOpen = ref(false);
        const isSaving = ref(false);
        const activeItem = ref("");
        const redirectTo = ref("/");

        // Receiver
        const audienceOptions = computed((): SelectOptionInterface[] => {
            return audiences.list.map((audience: AudienceModel) => ({
                label: `${audience.title}`,
                value: audience.id
            }));
        });

        const segmentOptions = computed((): SelectOptionInterface[] => {
            return segments.list.map((segment: SegmentModel) => ({
                label: `${segment.name} (${segment.leadCount})`,
                value: segment.id
            }));
        });
        const name = ref(mails.active.name);
        const shortName = computed(() => {
            return name.value.length > 38
                ? name.value.substring(0, 35) + "..."
                : name.value;
        });
        const defaultAudience = store.getters["audiences/getDefault"](),
            mailAudience = computed(() => {
                return audiences.list.find(
                    (audience: AudienceModel) =>
                        audience.id ===
                        (mails.active.category
                            ? mails.active.category.id
                            : defaultAudience.id)
                );
            });

        const audienceLabel = computed((): string => {
            return mails.active.category
                ? mails.active.category.title
                : defaultAudience
                ? defaultAudience.title
                : "-";
        });

        const audienceColor = computed((): string => {
            return mails.active.category
                ? mails.active.category.color
                : defaultAudience
                ? defaultAudience.color
                : null;
        });

        const defaultSegment = computed(() => {
                return segments.list.find(
                    (segment: SegmentModel) =>
                        segment.id ===
                        (defaultAudience ? defaultAudience.segment : 0)
                );
            }),
            mailSegment = computed(() => {
                return segments.list.find(
                    (segment: SegmentModel) =>
                        segment.id ===
                        (mails.active.lists.length
                            ? mails.active.lists[0].id
                            : 0)
                );
            }),
            listLabel = computed((): string => {
                return mailSegment.value
                    ? mailSegment.value.name
                    : mails.active.lists.length
                    ? mails.active.lists[0].name
                    : defaultSegment.value
                    ? defaultSegment.value.name
                    : "-";
            }),
            listLabelCount = computed((): number => {
                return mailSegment.value
                    ? mailSegment.value.leadCount
                    : defaultSegment.value
                    ? defaultSegment.value.leadCount
                    : "-";
            });

        const list = ref(
            mails.active.lists.length
                ? mails.active.lists[0].id
                : defaultSegment.value
                ? defaultSegment.value.id
                : 0
        );

        const audience = ref(
            mails.active.category
                ? mails.active.category.id
                : defaultAudience
                ? defaultAudience.id
                : null
        );

        // Sender
        const fromName = ref(
            mails.active.fromName ? mails.active.fromName : ""
        );
        const fromAddress = ref(
            mails.active.fromAddress ? mails.active.fromAddress : ""
        );
        const replyToAddress = ref(
            mails.active.replyToAddress ? mails.active.replyToAddress : ""
        );
        const validateEmail = (email: string) => {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        };
        const replyToAddressStatus = computed(() => {
            if (replyToAddress.value && !validateEmail(replyToAddress.value)) {
                return "error";
            }

            return "";
        });

        const replyToAddressDescription = computed(() => {
            if (replyToAddressStatus.value === "error") {
                return t("pages.mail.replyToAddressError");
            }
            return t("pages.mail.replyToText");
        });

        const emailOptions = computed((): SelectOptionInterface[] => {
            return mails.addressList.map((address: EmailAddressModel) => ({
                label: address.mailer_from_email,
                value: address.mailer_from_email
            }));
        });
        const defaultEmailAddress:
            | EmailAddressModel
            | undefined = mails.addressList.find(
            (address: EmailAddressModel) => {
                return address.default;
            }
        );
        if (fromAddress.value === "" && defaultEmailAddress !== undefined) {
            fromAddress.value = defaultEmailAddress.mailer_from_email;
            fromName.value = defaultEmailAddress.mailer_from_name;
            replyToAddress.value = defaultEmailAddress.mailer_reply_to;
        }

        // Subject
        const subject = ref(
            mails.active.subject
                ? mails.active.subject !== "Blank"
                    ? mails.active.subject
                    : ""
                : ""
        );
        const subjectStatus = computed((): string => {
            if (!subject.value.length) {
                return "danger";
            }
            return activeItem.value === "subject" ? "muted" : "success";
        });
        const preheaderText = ref(
            mails.active.preheaderText ? mails.active.preheaderText : ""
        );
        const subjectInputStatus = computed(() => {
                if (subject.value.length < 100) {
                    return "";
                }
                return "error";
            }),
            subjectInputDescription = computed(() => {
                if (subjectInputStatus.value === "error") {
                    return t("pages.mail.subjectInputError");
                }
                return "";
            }),
            preheaderTextInputStatus = computed(() => {
                if (
                    preheaderText.value.length > 0 &&
                    preheaderText.value.length < 85
                ) {
                    return "warning";
                }
                if (preheaderText.value.length > 100) {
                    return "error";
                }
                return "";
            }),
            preheaderTextInputDescription = computed(() => {
                if (preheaderTextInputStatus.value === "warning") {
                    return t("pages.mail.preheaderInputWarning");
                }
                if (preheaderTextInputStatus.value === "error") {
                    return t("pages.mail.preheaderInputError");
                }
                return t("pages.mail.preheaderTextText");
            });

        //Content
        const template = ref(
            mails.active.beeTemplate ? mails.active.beeTemplate : null
        );
        const html = ref(mails.active.customHtml || "");

        // UTM Tag
        const utmCampaign = ref(mails.active.utmTags.utmCampaign || "");
        const utmContent = ref(mails.active.utmTags.utmContent || "");
        const utmMedium = ref(mails.active.utmTags.utmMedium || "");
        const utmSource = ref(mails.active.utmTags.utmSource || "");
        const canSyncUtmName = ref(route.params.id === "0");

        // Schedule
        const datepickerOpen = ref(false);
        const today = ref(new Date());
        const dateTime = ref(
            mails.active.publishUp
                ? moment(mails.active.publishUp).toDate()
                : new Date()
        );
        const dateTimeFormatted = computed(() =>
            moment(dateTime.value).format("DD.MM.YYYY HH:mm")
        );
        const publishUpFormatted = computed(() =>
            mails.active.publishUp
                ? moment(mails.active.publishUp).format("DD.MM.YYYY HH:mm")
                : ""
        );
        const publishUp = ref(mails.active.publishUp || "");
        const publishDown = ref(mails.active.publishDown || "");
        const isPublished = ref(mails.active.isPublished);
        const scheduleConfirmationModalOpen = ref(false);
        const sendConfirmationModalOpen = ref(false);
        const congratulationsModalOpen = ref(false);
        const canSchedule = computed(
            (): boolean =>
                html.value.length &&
                mails.active.subject.length &&
                mails.active.subject !== "Blank"
        );
        const canSave = computed(
            (): boolean => canSchedule.value && !mails.active.publishUp
        );
        const formatDate = (datetime: string | object = {}) => {
            return moment(datetime).format("YYYY-MM-DD HH:mm");
        };

        const calendarDropdown = ref<InstanceType<typeof DropdownBase>>();

        const isDatepickerOpen = ref(false);

        const closeDatepicker = () => {
            datepickerOpen.value = false;
        };

        const save = async () => {
            try {
                isSaving.value = true;
                const mailData = {
                    ...mails.active,
                    name: name.value,
                    lists: [list.value],
                    fromName: fromName.value,
                    fromAddress: fromAddress.value,
                    replyToAddress: replyToAddress.value,
                    subject: subject.value || "Blank",
                    preheaderText: preheaderText.value,
                    beeTemplate: template.value,
                    customHtml: html.value || "",
                    utmTags: {
                        utmCampaign: utmCampaign.value,
                        utmContent: utmContent.value,
                        utmMedium: utmMedium.value,
                        utmSource: utmSource.value
                    },
                    publishUp: publishUp.value
                        ? formatDate(publishUp.value)
                        : null,
                    publishDown: publishDown.value
                        ? formatDate(publishDown.value)
                        : null,
                    isPublished: isPublished.value,
                    category: audience.value || null,
                    preferenceCenter: mailAudience.value
                        ? mailAudience.value.unsubscribe_page
                        : null,
                    unsubscribeForm: mails.active.unsubscribeForm
                        ? mails.active.unsubscribeForm.id
                        : null
                };
                const response = await store.dispatch(
                    "mails/editActive",
                    mailData
                );
                isSaving.value = false;
                activeItem.value = "";
                window.analytics.track("Mail Saved", {
                    userId: store.getters["user/emailHash"],
                    mailId: store.state.mails.active.id
                });
                if (response.status === 201) {
                    await router.replace({
                        name: "mail-edit",
                        params: {
                            id: response.data.email.id
                        }
                    });
                }
            } catch (error) {
                //TODO: handleError
                console.log("error", error);
            }
        };

        const reset = (routeId: string | string[] = "") => {
            activeItem.value = "";
            name.value = mails.active.name;
            list.value = mails.active.lists.length
                ? mails.active.lists[0].id
                : defaultSegment.value
                ? defaultSegment.value.id
                : 0;
            fromName.value = mails.active.fromName || "";
            fromAddress.value = mails.active.fromAddress || "";
            replyToAddress.value = mails.active.fromAddress || "";
            if (fromAddress.value === "" && defaultEmailAddress !== undefined) {
                fromAddress.value = defaultEmailAddress.mailer_from_email;
                fromName.value = defaultEmailAddress.mailer_from_name;
                replyToAddress.value = defaultEmailAddress.mailer_reply_to;
            }
            preheaderText.value = mails.active.preheaderText;
            subject.value = mails.active.subject
                ? mails.active.subject !== "Blank"
                    ? mails.active.subject
                    : ""
                : "";
            template.value = mails.active.beeTemplate
                ? mails.active.beeTemplate
                : null;
            html.value = mails.active.customHtml || "";
            utmCampaign.value = mails.active.utmTags.utmCampaign || "";
            utmContent.value = mails.active.utmTags.utmContent || "";
            utmMedium.value = mails.active.utmTags.utmMedium || "";
            utmSource.value = mails.active.utmTags.utmSource || "";
            unsavedChanges.value = routeId === "0";
            canSyncUtmName.value =
                routeId !== "0" ? canSyncUtmName.value : true;
            publishUp.value = mails.active.publishUp || "";
            publishDown.value = mails.active.publishDown || "";
            isPublished.value = mails.active.isPublished;
            dateTime.value = new Date();
        };

        const goToMails = async () => {
            await router.push({ name: "mails-list" });
        };

        const exit = async (shouldSave: boolean) => {
            unsavedChanges.value = false;
            if (shouldSave) {
                await save();
            }
            await router.push(redirectTo.value);
            store.commit("mails/CLEAR_ACTIVE");
        };

        const schedule = async () => {
            publishUp.value = moment(dateTime.value).format("YYYY-MM-DD HH:mm");
            publishDown.value = moment(dateTime.value)
                .add(6, "hours")
                .format("YYYY-MM-DD HH:mm");
            isPublished.value = true;
            try {
                await save();
                toast.add({
                    severity: "success",
                    summary: "Email scheduled",
                    detail: `Email successfully scheduled at
                     ${formatDate(dateTime.value)}`,
                    life: 3000
                });
                window.analytics.track("Mail Scheduled", {
                    userId: store.getters["user/emailHash"],
                    mailId: store.state.mails.active.id,
                    recipientCount: 1
                });
            } catch (e) {
                toast.add({
                    severity: "error",
                    summary: "Email not scheduled",
                    detail: "Email schedule error",
                    life: 3000
                });
            } finally {
                scheduleConfirmationModalOpen.value = false;
            }
        };
        const send = async () => {
            publishUp.value = moment().format("YYYY-MM-DD HH:mm");
            publishDown.value = moment()
                .add(6, "hours")
                .format("YYYY-MM-DD HH:mm");
            isPublished.value = true;
            try {
                await save();
                congratulationsModalOpen.value = true;
                window.analytics.track("Mail Sent", {
                    userId: store.getters["user/emailHash"],
                    mailId: store.state.mails.active.id,
                    recipientCount: 1
                });
            } catch (e) {
                toast.add({
                    severity: "error",
                    summary: "Email not sent",
                    detail: "Email sending error",
                    life: 3000
                });
            } finally {
                sendConfirmationModalOpen.value = false;
            }
        };

        const openScheduleConfirmationModal = () => {
            scheduleConfirmationModalOpen.value = true;
            closeDatepicker();
        };
        const openSendConfirmationModal = () => {
            dateTime.value = new Date();
            sendConfirmationModalOpen.value = true;
            closeDatepicker();
        };
        const scheduleButtonHandler = () => {
            if (isDatepickerOpen.value) {
                const startTime = moment();
                const endTime = moment(dateTime.value);

                const hoursDiff = endTime.diff(startTime, "hours");
                if (hoursDiff < 2) {
                    openScheduleConfirmationModal();
                } else {
                    schedule();
                }
                isDatepickerOpen.value = false;
            }
        };
        const clearSchedule = async () => {
            publishUp.value = null;
            publishDown.value = null;
            isPublished.value = false;
            isDatepickerOpen.value = false;
            dateTime.value = new Date();
            try {
                await save();
            } catch (e) {
                toast.add({
                    severity: "error",
                    summary: "Schedule error",
                    detail: "Email scheduling error",
                    life: 3000
                });
            }
        };

        const viewMail = async () => {
            await router.push({
                name: "mail-view",
                params: { id: mails.active.id }
            });
        };

        const closeCongratulationsModal = () => {
            congratulationsModalOpen.value = false;
            viewMail();
        };

        watch(fromAddress, (val: string) => {
            const email: EmailAddressModel | undefined = mails.addressList.find(
                (address: EmailAddressModel) =>
                    address.mailer_from_email === val
            );
            if (email !== undefined) {
                fromName.value = email.mailer_from_name;

                if (email.mailer_reply_to && email.mailer_reply_to !== "") {
                    replyToAddress.value = email.mailer_reply_to;
                }
            }
        });

        watch(name, (val: string, oldVal: string) => {
            if (canSyncUtmName.value && oldVal === utmCampaign.value) {
                utmCampaign.value = val;
            }
        });

        watch(audience, (val: number) => {
            list.value = audiences.list.find(
                (audience: AudienceModel) => audience.id === val
            ).segment;
        });

        onBeforeRouteUpdate(to => {
            reset(to.params.id);
        });

        onBeforeRouteLeave((to, from, next) => {
            if (unsavedChanges.value) {
                unsavedModalOpen.value = true;
                redirectTo.value = to.fullPath;
                return;
            }
            next();
        });

        const templateType = ref("mail");
        const templateContent = ref("");
        const templateName = ref("");
        const templateKey = ref("");
        const editTemplate = async () => {
            if (template.value === null) {
                const chosenTemplate: TemplateModel = await openDialog(
                    ChooseTemplateModal
                );
                if (chosenTemplate !== null) {
                    templateContent.value = chosenTemplate.content;
                }
            } else {
                templateContent.value = JSON.parse(
                    decodeUnicode(template.value)
                );
            }

            templateName.value = name.value;
            templateKey.value = `mail_${mails.active.id}`;
        };

        const saveTemplateContent = (payload: {
            templateContent: string;
            htmlContent: string;
            templateName: string;
        }) => {
            template.value = encodeUnicode(
                JSON.stringify(payload.templateContent)
            );
            html.value = payload.htmlContent;
            name.value = payload.templateName;
            save();
        };

        const exitEditor = () => {
            templateContent.value = "";
        };

        return {
            mail,
            name,
            shortName,
            list,
            listLabel,
            listLabelCount,
            segmentOptions,
            activeItem,
            fromName,
            fromAddress,
            replyToAddress,
            replyToAddressStatus,
            replyToAddressDescription,
            emailOptions,
            subject,
            preheaderText,
            subjectStatus,
            subjectInputStatus,
            subjectInputDescription,
            preheaderTextInputStatus,
            preheaderTextInputDescription,
            html,
            template,
            utmCampaign,
            utmContent,
            utmMedium,
            utmSource,
            isSaving,
            unsavedModalOpen,
            datepickerOpen,
            dateTime,
            dateTimeFormatted,
            publishUpFormatted,
            today,
            calendarDropdown,
            isDatepickerOpen,
            canSchedule,
            canSave,
            scheduleConfirmationModalOpen,
            sendConfirmationModalOpen,
            congratulationsModalOpen,
            scheduleButtonHandler,
            clearSchedule,
            openScheduleConfirmationModal,
            openSendConfirmationModal,
            schedule,
            send,
            closeDatepicker,
            goToMails,
            exit,
            save,
            reset,
            closeCongratulationsModal,
            audienceLabel,
            audienceOptions,
            audience,
            audienceColor,
            templateType,
            templateContent,
            templateName,
            templateKey,
            editTemplate,
            saveTemplateContent,
            exitEditor,
            sendTestMail
        };
    }
});
